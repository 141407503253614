import http from "../utils/http";
import base from "./base";

export default {
  createOrUpdate(params) {
    return http.post(`${base.url}/admin/digEvent/createOrUpdate`, params);
  },
  digEventList(params) {
    return http.get(`${base.url}/admin/digEvent/digEventList`, params);
  },
  digEventDetail(params) {
    return http.get(`${base.url}/admin/digEvent/digEventDetail`, params);
  },
  digEventOptions(params) {
    return http.get(`${base.url}/admin/digEvent/digEventOptions`, params);
  },
  digVideoCreateOrUpdate(params) {
    return http.post(`${base.url}/admin/digVideo/createOrUpdate`, params);
  },
  digVideoDetail(params) {
    return http.get(`${base.url}/admin/digVideo/detail`, params);
  },
  digVideoList(params) {
    return http.get(`${base.url}/admin/digVideo/list`, params);
  },
  digPptCreateOrUpdate(params) {
    return http.post(`${base.url}/admin/digPpt/createOrUpdate`, params);
  },
  digPptDetail(params) {
    return http.get(`${base.url}/admin/digPpt/detail`, params);
  },
  digPptList(params) {
    return http.get(`${base.url}/admin/digPpt/list`, params);
  },
};
