import auth from "./auth";
import event from "./event";
import digEvent from "./digEvent";
import digContact from "./digContact";
import digEventGoods from "./digEventGoods";
import digEventAgenda from "./digEventAgenda";
import digEventAgendaSchedule from "./digEventAgendaSchedule";
import slide from "./slide";
import feedback from "./feedback";
import service from "./service";
import invoice from "./invoice";
import order from "./digEventOrders";
import analysis from "./analysis";
import relationUser from "./relationUser";
import digReport from "./digReport";
import category from "./category";
import basicConfig from "./basicConfig";
import common from "./common";
import digTopic from "./digTopic";
import industry from "./industry";

export default {
  auth,
  event,
  digEvent,
  digContact,
  digEventGoods,
  digEventAgenda,
  digEventAgendaSchedule,
  service,
  slide,
  feedback,
  invoice,
  order,
  analysis,
  relationUser,
  digReport,
  category,
  basicConfig,
  common,
  digTopic,
  industry
};
