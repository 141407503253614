import http from '../utils/http'
import base from './base'

export default {
  updateFeedback(params) {
    return http.post(`${base.url}/admin/feedback/updateFeedback`, params)
  },
  feedbackList(params) {
    return http.get(`${base.url}/admin/feedback/feedbackList`, params)
  },
  feedbackDetail(params) {
    return http.get(`${base.url}/admin/feedback/feedbackDetail`, params)
  }
}
