import http from "../utils/http";
import base from "./base";

export default {
  digEventAgendaList(params) {
    return http.get(`${base.url}/admin/digEventAgenda/digEventAgendaList`, params)
  },
  createOrUpdate(params) {
    return http.post(`${base.url}/admin/digEventAgenda/createOrUpdate`, params)
  },
  digEventAgendaDetail(params) {
    return http.get(`${base.url}/admin/digEventAgenda/digEventAgendaDetail`, params)
  }
}
