<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
};
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.default-select-width {
  width: 220px !important;
}
.color-success {
  color: #67c33a;
}
.clipboard {
  color: #204b7c;
  &:hover {
    cursor: pointer;
  }
}
.span-tip {
  margin: 0 10px;
  color: #F44236;
  font-size: 12px;
}

.search-box,
.table-box,
.paginate-box {
  margin: 10px 10px 0 10px;
  padding: 10px;
  background: #ffffff;
  border-radius: 5px;
}
.search-box {
  padding-bottom: 0;
}
</style>
