let url = 'http://bizevent-service.work'

// 环境的切换
if (process.env.NODE_ENV === 'test') {
  url = 'https://test.service.shbizev.com'
} else if (process.env.NODE_ENV === 'production') {
  url = 'https://service.shbizev.com'
}

const base = {
  url,
  uploadUrl: url + '/api/service/upload',
  uploadPdfUrl: url + '/api/service/uploadPdf'
}
export default base
