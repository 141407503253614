export default {
  objectGetValue(obj, prop) {
    let propsArr = prop.split(".");
    let temp = obj;
    let currProp;

    while ((currProp = propsArr.shift())) {
      if (temp[currProp] === undefined || temp[currProp] === null) {
        return undefined;
      } else {
        temp = temp[currProp];
      }
    }
    return temp;
  },
  v(obj, prop) {
    let propsArr = prop.split(".");
    let temp = obj;
    let currProp;

    while ((currProp = propsArr.shift())) {
      if (temp[currProp] === undefined || temp[currProp] === null) {
        return undefined;
      } else {
        temp = temp[currProp];
      }
    }
    return temp;
  },
};
