import http from '../utils/http'
import base from './base'

export default {
  updateInvoice(params) {
    return http.post(`${base.url}/admin/invoice/updateInvoice`, params)
  },
  invoiceList(params) {
    return http.get(`${base.url}/admin/invoice/invoiceList`, params)
  },
  invoiceDetail(params) {
    return http.get(`${base.url}/admin/invoice/invoiceDetail`, params)
  }
}
