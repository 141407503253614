import http from "../utils/http";
import base from "./base";

export default {
  createOrUpdate(params) {
    return http.post(`${base.url}/admin/digReport/createOrUpdate`, params);
  },
  digReportList(params) {
    return http.get(`${base.url}/admin/digReport/digReportList`, params);
  },
  digReportDetail(params) {
    return http.get(`${base.url}/admin/digReport/digReportDetail`, params);
  },
  getPdfDetail(params) {
    return http.get(`${base.url}/admin/digReport/getPdfDetail`, params);
  },
};
