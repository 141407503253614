import http from '../utils/http'
import base from './base'

export default {
  updateOrder(params) {
    return http.post(`${base.url}/admin/order/updateOrder`, params)
  },
  orderList(params) {
    return http.get(`${base.url}/admin/order/orderList`, params)
  },
  orderDetail(params) {
    return http.get(`${base.url}/admin/order/orderDetail`, params)
  },
  orderFlowList(params) {
    return http.get(`${base.url}/admin/order/orderFlowList`, params)
  }
}
