import http from "../utils/http";
import base from "./base";

export default {
  digEventAgendaScheduleList(params) {
    return http.get(
      `${base.url}/admin/digEventAgendaSchedule/digEventAgendaScheduleList`,
      params
    );
  },
  digEventAgendaScheduleOptions(params) {
    return http.get(
      `${base.url}/admin/digEventAgendaSchedule/digEventAgendaScheduleOptions`,
      params
    );
  },
  digEventAgendaScheduleDetail(params) {
    return http.get(
      `${base.url}/admin/digEventAgendaSchedule/digEventAgendaScheduleDetail`,
      params
    );
  },
  createOrUpdate(params) {
    return http.post(
      `${base.url}/admin/digEventAgendaSchedule/createOrUpdate`,
      params
    );
  },
};
