import http from "../utils/http";
import base from "./base";

export default {
  createOrUpdate(params) {
    return http.post(`${base.url}/admin/digTopic/createOrUpdate`, params);
  },
  digTopicList(params) {
    return http.get(`${base.url}/admin/digTopic/digTopicList`, params);
  },
  digTopicDetail(params) {
    return http.get(`${base.url}/admin/digTopic/digTopicDetail`, params);
  },
  getTopicDetail(params) {
    return http.get(`${base.url}/admin/digTopic/getTopicDetail`, params);
  },
};
