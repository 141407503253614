const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login"),
  },
  {
    path: "/",
    name: "Index",
    component: () => import("../layout/Layout"),
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/Dashboard"),
      },
    ],
  },
  {
    path: "/digital",
    name: "Digital",
    component: () => import("../layout/Layout"),
    redirect: "/digital/dig_event_list",
    children: [
      {
        path: "dig_event_list",
        name: "DigEventList",
        component: () => import("../views/digital/DigEventList"),
      },
      {
        path: "dig_event_goods_list",
        name: "DigEventGoodsList",
        component: () => import("../views/digital/DigEventGoodsList"),
      },
      {
        path: "dig_event_agenda_list",
        name: "DigEventAgendaList",
        component: () => import("../views/digital/DigEventAgendaList.vue"),
      },
      {
        path: "slide_list",
        name: "SlideList",
        component: () => import("../views/digital/SlideList"),
      },
      {
        path: "dig_report_list",
        name: "DigReportList",
        component: () => import("../views/digital/DigReportList.vue"),
      },
      {
        path: "feedback_list",
        name: "FeedbackList",
        component: () => import("../views/digital/FeedbackList"),
      },
      {
        path: "dig_video_list",
        name: "DigVideoList",
        component: () => import("../views/digital/DigVideoList.vue"),
      },
      {
        path: "dig_ppt_list",
        name: "DigPptList",
        component: () => import("../views/digital/DigPptList.vue"),
      },
      {
        path: "dig_topic_list",
        name: "DigTopicList",
        component: () => import("../views/digital/DigTopicList.vue"),
      },
    ],
  },
  {
    path: "/finance",
    name: "Finance",
    component: () => import("../layout/Layout.vue"),
    children: [
      {
        path: "invoice_list",
        name: "InvoiceList",
        component: () => import("../views/finance/InvoiceList"),
      },
      {
        path: "dig_event_order_list",
        name: "DigEventOrderList",
        component: () => import("../views/finance/DigEventOrderList"),
      },
    ],
  },
  {
    path: "/analysis",
    name: "Analysis",
    component: () => import("../layout/Layout"),
    redirect: "/analysis/visit_page_list",
    children: [
      {
        path: "visit_page_list",
        name: "VisitPageList",
        component: () => import("../views/analysis/VisitPageList"),
      },
      {
        path: "visit_distribution",
        name: "VisitDistribution",
        component: () => import("../views/analysis/VisitDistribution"),
      },
      {
        path: "order_analysis",
        name: "OrderAnalysis",
        component: () => import("../views/analysis/OrderAnalysis"),
      },
      {
        path: "dig_contact_log_list",
        name: "DigContactLogList",
        component: () => import("../views/analysis/DigContactLogList"),
      },
    ],
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../layout/Layout"),
    redirect: "/account/relation_user_list",
    children: [
      {
        path: "relation_user_list",
        name: "RelationUserList",
        component: () => import("../views/account/RelationUserList"),
      },
    ],
  },
  {
    path: "/basic_config",
    name: "BasicConfig",
    component: () => import("../layout/Layout.vue"),
    redirect: "/basic_config/category_list",
    children: [
      {
        path: "category_list",
        name: "CategoryList",
        component: () => import("../views/basic_config/CategoryList.vue"),
      },
      {
        path: "industry_list",
        name: "IndustryList",
        component: () => import("../views/basic_config/IndustryList.vue"),
      },
    ],
  },
];

export default routes;
