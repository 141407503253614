import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "@/router/routes"
import localStorage from "@/utils/localStorage"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')

  const whiteList = [
    '/login'
  ];

  if (whiteList.indexOf(to.path) === -1 && !token) {
    next({path: '/login'})
  } else {
    next()
  }
})

export default router
