import ClipboardJS from 'clipboard'
import Vue from 'vue'
import { Message } from 'element-ui'

const clipboard = {
  install: function (Vue) {
    Vue.prototype.$clipboard = new ClipboardJS('.clipboard')

    Vue.prototype.$clipboard.on('success', function (e) {
      Message.success('复制成功：' + e.text)
    })

    Vue.prototype.$clipboard.on('error', function () {
      Message.error('复制失败')
    })
  }
}

Vue.use(clipboard)
