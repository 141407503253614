import http from '../utils/http'
import base from './base'

export default {
  createOrUpdate(params) {
    return http.post(`${base.url}/admin/slide/createOrUpdate`, params)
  },
  slideList(params) {
    return http.get(`${base.url}/admin/slide/slideList`, params)
  },
  slideDetail(params) {
    return http.get(`${base.url}/admin/slide/slideDetail`, params)
  },
}
