import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/clipboard'
import './plugins/vueTinymce'
import moment from "moment";

import api from './api'
import helpers from './utils/helpers'

Vue.config.productionTip = false

Vue.prototype.$api = api
Vue.prototype.$helpers = helpers
Vue.prototype.$moment = moment;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
