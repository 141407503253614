import http from '../utils/http'
import base from './base'

export default {
  getVisitPage(params) {
    return http.get(`${base.url}/admin/analysis/getVisitPage`, params)
  },
  analysisVisitLogList(params) {
    return http.get(`${base.url}/admin/analysis/analysisVisitLogList`, params)
  },
  orderAnalysis(params) {
    return http.get(`${base.url}/admin/analysis/orderAnalysis`, params)
  },
  getVisitDistribution(params) {
    return http.get(`${base.url}/admin/analysis/getVisitDistribution`, params)
  },
}
