import http from '../utils/http'
import base from './base'

export default {
  createOrUpdate(params) {
    return http.post(`${base.url}/admin/digEventGoods/createOrUpdate`, params)
  },
  digEventGoodsList(params) {
    return http.get(`${base.url}/admin/digEventGoods/digEventGoodsList`, params)
  },
  digEventGoodsDetail(params) {
    return http.get(`${base.url}/admin/digEventGoods/digEventGoodsDetail`, params)
  },
}
