import http from "../utils/http";
import base from "./base";

export default {
  digContactList(params) {
    return http.get(`${base.url}/admin/digContact/digContactList`, params)
  },
  digContactDetail(params) {
    return http.get(`${base.url}/admin/digContact/digContactDetail`, params)
  },
  updateDigContact(params) {
    return http.post(`${base.url}/admin/digContact/updateDigContact`, params)
  }
}
