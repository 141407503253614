const state = {
  aside_menu_collapse: false,
  menus: [
    { name: "首页", icon: "el-icon-menu", path: "/dashboard", children: [] },
    {
      name: "数字营销",
      icon: "el-icon-data-line",
      path: "/digital",
      children: [
        { name: "会议列表", path: "/digital/dig_event_list" },
        { name: "会议议程", path: "/digital/dig_event_agenda_list" },
        { name: "会议商品", path: "/digital/dig_event_goods_list" },
        { name: "会议视频", path: "/digital/dig_video_list" },
        { name: "会议PPT", path: "/digital/dig_ppt_list" },
        { name: "轮播图", path: "/digital/slide_list" },
        { name: "热议话题", path: "/digital/dig_topic_list" },
        { name: "白皮书", path: "/digital/dig_report_list" },
        { name: "用户反馈", path: "/digital/feedback_list" },
      ],
    },
    {
      name: "财务管理",
      icon: "el-icon-notebook-1",
      path: "/finance",
      children: [
        { name: "订单管理", path: "/finance/dig_event_order_list" },
        { name: "发票管理", path: "/finance/invoice_list" },
      ],
    },
    {
      name: "数据统计",
      icon: "el-icon-s-data",
      path: "/analysis",
      children: [
        { name: "访问页面", path: "/analysis/visit_page_list" },
        { name: "访问分布", path: "/analysis/visit_distribution" },
        { name: "订单统计", path: "/analysis/order_analysis" },
        { name: "联系我们", path: "/analysis/dig_contact_log_list" },
      ],
    },
    {
      name: "账号管理",
      icon: "el-icon-user-solid",
      path: "/account",
      children: [{ name: "微信用户", path: "/account/relation_user_list" }],
    },
    {
      name: "基础配置",
      icon: "el-icon-s-tools",
      path: "/basic_config",
      children: [
        { name: "领域分类", path: "/basic_config/category_list" },
        { name: "会议分类", path: "/basic_config/industry_list" },
      ],
    },
  ],
};

const getters = {
  aside_menu_collapse: (state) => state.aside_menu_collapse,
  menus: (state) => state.menus,
};

const actions = {};

const mutations = {
  UPDATE_ASIDE_MENU_COLLAPSE(state, status) {
    state.aside_menu_collapse = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
