import http from "../utils/http";
import base from "./base";

export default {
  categoryList(params) {
    return http.get(`${base.url}/admin/category/categoryList`, params);
  },
  categoryCreateOrUpdate(params) {
    return http.post(`${base.url}/admin/category/createOrUpdate`, params);
  },
  categoryDetail(params) {
    return http.get(`${base.url}/admin/category/categoryDetail`, params);
  },
  categoryOptions(params) {
    return http.get(`${base.url}/admin/category/categoryOptions`, params);
  },
  industryList(params) {
    return http.get(`${base.url}/admin/industry/industryList`, params);
  },
  industryCreateOrUpdate(params) {
    return http.post(`${base.url}/admin/industry/createOrUpdate`, params);
  },
  industryDetail(params) {
    return http.get(`${base.url}/admin/industry/industryDetail`, params);
  },
  industryOptions(params) {
    return http.get(`${base.url}/admin/industry/industryOptions`, params);
  },
};
