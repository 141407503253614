/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios"
import router from "@/router"
import localStorage from "@/utils/localStorage"
import {
  Message
} from "element-ui"

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: '/login',
    query: {
      redirect: router.currentRoute.fullPath
    }
  }).then()
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 * @param res
 */
const errorHandle = (status, res) => {
  console.log(res)
  // 状态码判断
  switch (status) {
    case 401:
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      Message({
        message: '请重新进行登录',
        type: 'error'
      })
      setTimeout(() => {
        toLogin()
      }, 1000)
      break
    case 403:
      Message({
        message: '当前资源禁止访问',
        type: 'error'
      })
      break
      // 404请求不存在
    case 404:
      Message({
        message: '当前资源不存在',
        type: 'error'
      })
      break
      // 表单验证不通过
    case 422:
      Message({
        message: res.msg,
        type: 'error'
      })
      break
    default:
      Message({
        message: res.msg || res.toString(),
        type: 'error'
      })
  }
}

// 创建axios实例
const instance = axios.create({
  timeout: 1000 * 60
})

// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/json'
instance.defaults.headers.get['Content-Type'] = 'application/json'

// 请求拦截器
instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    token && (config.headers.Authorization = token)
    return config
  },
  error => Promise.error(error)
)

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => Promise.resolve(res.data),
  // 请求失败
  error => {
    const {
      response
    } = error
    if (response) {
      errorHandle(response.status, response.data)
    } else {
      if (!window.navigator.onLine) {
        Message({
          message: '当前网络已中断！',
          type: 'error'
        })
      } else {
        console.log(error.toString())
        Message({
          message: '未知错误 -1001',
          type: 'error'
        })
      }
    }
    return Promise.reject(response)
  }
)

export default {
  axios: instance,
  get: function (url, params) {
    return instance.get(url, {
      params
    })
  },
  post: function (url, params) {
    return instance.post(url, params)
  }
}
